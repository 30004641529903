<template>
    <!-- Modal -->
    <div class="modal center-modal fade" :id="`price-detail-${index}`" tabindex="-1">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Estimated Price List</h5>
                    <button type="button" class="close" data-dismiss="modal">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div v-for="detail in priceList" :key="detail.id" class="row">
                        <div class="col-3">
                            {{ detail.weight }} kg
                        </div>
                        <div class="col-4">
                            ---
                        </div>
                        <div class="col-5">
                            N {{ new Intl.NumberFormat().format(detail.price) }}
                        </div>
                    </div>
                </div>
                <div class="modal-footer modal-footer-uniform">
                    <button type="button" class="btn btn-bold btn-pure btn-secondary" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
    <!-- /.modal -->
</template>

<script>
export default {
    props: ['priceList', 'index'],
}
</script>

