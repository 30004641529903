<template>
    <section class="content-wrapper">
        <div class="row">
            <div class="col-12">
                <div class="box box-solid box-primary">
                    <div class="box-header">
                        <h4 v-if="customer" class="box-subtitle text-white">Create Order For {{customer.first_name}}</h4>
                    </div>
                    <div class="box-body">
                        <form @submit.prevent="onSubmit" class="form-horizontal form-element mb-20 p-30">
                            <div class="row">
                                <p class="error" v-if="error">{{errorMessage}}</p>
                            </div>
                            <div class="row">
                                <div v-if="customer && customer.has_subscription" class="form-group">
                                    <label class="text-warning">This User Has Subscription</label>
                                    <div class="demo-checkbox">
                                        <input type="checkbox" @change="onUseSubscriptionValueChange" class="chk-col-yellow" id="subscription" v-model="useSubscription" />
                                        <label for="subscription">Use {{customer.has_subscription.name}} Subscription</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-md-6 col-lg-6 pr-60">
                                    <h3>Pickup Details</h3>
                                    <div class="form-group">
                                        <input v-model="pickupName" type="text" class="form-control" placeholder="Name" required>
                                        <p class="error" v-if="pickupNameError">{{pickupNameErrorMessage}}</p>
                                    </div>
                                    <div class="form-group">
                                        <input v-model="pickupPhone" type="text" class="form-control" placeholder="Phone" required>
                                        <p class="error" v-if="pickupPhoneError">{{pickupPhoneErrorMessage}}</p>
                                    </div>
                                    <div class="form-group">
                                        <input v-model="pickupEmail" type="text" class="form-control" placeholder="Email" required>
                                        <p class="error" v-if="pickupEmailError">{{pickupEmailErrorMessage}}</p>
                                    </div>
                                    <div v-if="useSubscription" class="form-group">
                                        <label>Select Subscription Address To Use</label>
                                        <div v-for="location in customer.has_subscription.locations" :key="location.id" class="demo-checkbox">
                                            <input type="radio" name="subscription_address_id" class="radio-col-green" :id="`subscription_address_${location.id}`" :value="location.id" v-model="subscriptionSelectedAddressID" @change="onLocationChanged"/>
                                            <label :for="`subscription_address_${location.id}`">{{ location.address }}, {{ location.city.name }}, {{ location.state.name }}, {{ location.country.name }}</label>
                                        </div>
                                        <p class="error" v-if="subscriptionSelectedAddressIDError">{{subscriptionSelectedAddressIDErrorMessage}}</p>
                                    </div>
                                    <div v-else>
                                        <div class="form-group">
                                            <input v-model="pickupAddress" type="text" class="form-control" placeholder="Address" required>
                                            <p class="error" v-if="pickupAddressError">{{pickupAddressErrorMessage}}</p>
                                        </div>
                                        <div class="form-group">
                                            <label>Select Country</label>
                                            <select v-model="pickupCountry" @change="onPickupCountryValueChange" class="form-control">
                                                <option value="">Select Country</option>
                                                <option v-for="pickupCountry in pickupCountryList" :key="pickupCountry.id" :value="pickupCountry">{{pickupCountry.name}}</option>
                                            </select>
                                            <p class="error" v-if="pickupCountryError">{{pickupCountryErrorMessage}}</p>
                                        </div>
                                        <div v-if="pickupStateList.length" class="form-group">
                                            <label>Select State</label>
                                            <select v-model="pickupState" @change="onPickupStateValueChange" class="form-control">
                                                <option value="">Select State</option>
                                                <option v-for="pickupState in pickupStateList" :key="pickupState.id" :value="pickupState">{{pickupState.name}}</option>
                                            </select>
                                            <p class="error" v-if="pickupStateError">{{pickupStateErrorMessage}}</p>
                                        </div>
                                        <div v-if="pickupCityList.length" class="form-group">
                                            <label>Select City</label>
                                            <select v-model="pickupCity" @change="onPickupCityValueChange" class="form-control">
                                                <option value="">Select City</option>
                                                <option v-for="pickupCity in pickupCityList" :key="pickupCity.id" :value="pickupCity">{{pickupCity.name}}</option>
                                            </select>
                                            <p class="error" v-if="pickupCityError">{{pickupCityErrorMessage}}</p>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="controls">
                                            <textarea cols="8" rows="6" v-model="pickupExtraInformation" class="form-control" placeholder="Extra Information For Pickup (optional)"></textarea>
                                        </div>
                                        <p class="error" v-if="pickupExtraInformationError">{{pickupExtraInformationErrorMessage}}</p>
                                    </div>
                                    <div class="form-group">
                                        <input v-model="itemDescription" type="text" class="form-control" placeholder="Item Description" required>
                                        <p class="error" v-if="itemDescriptionError">{{itemDescriptionErrorMessage}}</p>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-6">
                                    <h3>
                                        Delivery Details 
                                        <NewDeliveryDetail :pickupCountryList="pickupCountryList" @saveDetail="onSaveDeliveryDetail"/>
                                    </h3>
                                    <div>
                                        <span><button type="button" class="btn btn-success btn-sm" data-toggle="modal" data-target="#new-delivery">+ New Delivery Detail</button></span> &nbsp;
                                        <span>
                                            <label for="input-file-import" class="btn btn-success btn-sm">
                                                <i class="fa fa-cloud-upload"></i> Upload Excel Sheet
                                            </label>
                                            <input style="display:none;" type="file" id="input-file-import" name="file_import" ref="excelFile"  @change="onFileChange"/>
                                        </span>
                                        <span v-html="excelFileName" class="text-sm"></span>
                                    </div>
                                    
                                    <div>
                                        <div v-for="(deliveryDetail, index) in deliveryDetails" :key="index" class="card">
                                            <div class="card-body">
                                                <h4 class="card-text">{{deliveryDetail.name}}</h4>
                                                <p class="card-text">{{deliveryDetail.phone}}</p>
                                                <p class="card-text">{{deliveryDetail.email}}</p>
                                                <p class="card-text">{{deliveryDetail.address}}, {{deliveryDetail.cityName}} {{deliveryDetail.stateName}}, {{deliveryDetail.countryName}}.</p>
                                                <p class="card-text">{{deliveryDetail.extraInformation}}</p>
                                            </div>
                                            <div class="card-footer justify-content-between d-flex">
                                                <div>
                                                    <span v-if="!useSubscription">Price: ₦ {{deliveryDetail.price ?  deliveryDetail.price : ''}}</span> &nbsp; &nbsp;
                                                    <span v-if="deliveryDetail.priceList"><button class="btn btn-xs btn-info" data-toggle="modal" :data-target="`#price-detail-${index}`">view price list</button></span>
                                                    <PriceDetail :priceList="deliveryDetail.priceList" :index="index"/>
                                                </div>
                                                <button class="btn btn-sm btn-danger" @click="removeDeliveryDetail(index)">Remove</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div v-if="showPriceEstimate && !useSubscription" class="row mb-30 mt-30">
                                <div class="col-12 col-lg-12">
                                    <p class="text-center text-info">
                                        {{ computeEstimatedPrice }}
                                        <button v-if="!isNaN(estimatedPrice)" type="button" class="btn btn-info btn-xs" data-toggle="modal" data-target="#price-detail">View More</button>
                                        <PriceDetail :priceList="estimatedPriceList"/>
                                    </p>
                                </div>
                            </div> -->
                            <div class="row mb-30 mt-30">
                                <div class="col-12 col-md-6 col-lg-6 pr-60">
                                    <!-- <div class="form-group">
                                        <div class="controls">
                                            <textarea cols="8" rows="6" v-model="pickupExtraInformation" class="form-control" placeholder="Extra Information For Pickup (optional)"></textarea>
                                        </div>
                                        <p class="error" v-if="pickupExtraInformationError">{{pickupExtraInformationErrorMessage}}</p>
                                    </div>
                                    <div class="form-group">
                                        <input v-model="itemDescription" type="text" class="form-control" placeholder="Item Description" required>
                                        <p class="error" v-if="itemDescriptionError">{{itemDescriptionErrorMessage}}</p>
                                    </div> -->
                                </div>
                                <div class="col-12 col-md-6 col-lg-6">
                                    <!-- <div class="form-group">
                                        <div class="controls">
                                            <textarea cols="8" rows="6" v-model="deliveryExtraInformation" class="form-control" placeholder="Extra Information For Delivery (optional)"></textarea>
                                        </div>
                                        <p class="error" v-if="deliveryExtraInformationError">{{deliveryExtraInformationErrorMessage}}</p>
                                    </div> -->
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-md-6 col-lg-6 mt-60">
                                    <button @click="placeOrder" class="btn btn-primary btn-large">Place Order<span v-if="loading" class="spinner-border text-white"></span></button>
                                </div>
                            </div>
                            <div class="row">
                                <p class="error" v-if="error">{{errorMessage}}</p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { repository } from '../../presenter';
import PriceDetail from '../../components/Order/PriceDetail';
import NewDeliveryDetail from '../../components/Order/DeliveryDetail';
export default {
    components:{
        PriceDetail,
        NewDeliveryDetail,
    },
    props: ['customerData'],
    data(){
        return{
            pickupCountryList: [],
            pickupCountry: {},
            pickupCountryName: '',
            pickupCountryError: false,
            pickupCountryErrorMessage: '',

            pickupStateList: [],
            pickupState: {},
            pickupStateName: '',
            pickupStateError: false,
            pickupStateErrorMessage: '',

            pickupCityList: [],
            pickupCity: {},
            pickupCityName: '',
            pickupCityError: false,
            pickupCityErrorMessage: '',

            pickupName: '',
            pickupNameError: false,
            pickupNameErrorMessage: '',
            pickupPhone: '',
            pickupPhoneError: false,
            pickupPhoneErrorMessage: '',
            pickupEmail: '',
            pickupEmailError: false,
            pickupEmailErrorMessage: '',
            pickupAddress: '',
            pickupAddressError: false,
            pickupAddressErrorMessage: '',
            // pickupCountryID: '',
            pickupCountryError: false,
            pickupCountryErrorMessage: '',
            // pickupStateID: '',
            pickupStateError: false,
            pickupStateErrorMessage: '',
            // pickupCityID: '',
            pickupCityError: false,
            pickupCityErrorMessage: '',
            pickupExtraInformation: '',
            pickupExtraInformationError: false,
            pickupExtraInformationErrorMessage: '',
            itemDescription: '',
            itemDescriptionError: false,
            itemDescriptionErrorMessage: '',









            excelFile: '',
            excelFileName: '',
            deliveryDetails: [],



            // showPriceEstimate: false,
            // estimatedPrice: '',
            // estimatedPriceList: [],




            useSubscription: false,
            subscriptionSelectedAddressID: 0,
            subscriptionSelectedAddressIDError: false,
            subscriptionSelectedAddressIDErrorMessage: '',


            
            



            customer : this.customerData ? JSON.parse(this.customerData) : null,
            loading: false,
            error: false,
            errorMessage: '',
            publicPath: process.env.BASE_URL,


        }
    },

    watch: {
        customer: function (val) {
            if (!val) return;
            this.useSubscription = this.customer && this.customer.has_subscription ? true : false
        }
    },

    mounted() {
        const sweetAlert = document.createElement('script')
        sweetAlert.setAttribute('src', `${this.publicPath}assets/styles/vendor_components/sweetalert/sweetalert.min.js`)
        document.head.appendChild(sweetAlert)
        if (this.customerData==null){
            this.loadCustomerData();
        }
        this.fetchPickupCountryData(true);
    },

    computed: {
        // computeEstimatedPrice() {
        //     return isNaN(this.estimatedPrice) ? `Estimated Price will be Calculated` : `Estimated Price Price For Delivery: ₦ ${this.estimatedPrice}`;
        // }
    },

    methods: {

        onFileChange(e) {
            this.excelFile = e.target.files[0];
            this.excelFileName = this.excelFile.name;
            
        },


        onSaveDeliveryDetail(data) {
            console.log("data to push to delivery detail is ", data)
            this.deliveryDetails.push(data)
            console.log("after pushing, list is ", this.deliveryDetails)
            this.onLocationChanged()
        },

        removeDeliveryDetail(index) {
            this.deliveryDetails.splice(index, 1);
        },

        resetPickupCountryData(){
            this.pickupCountryList = [];
            this.pickupCountry = {};
            this.pickupCountryError = false;
            this.pickupCountryErrorMessage = '';
        },
        

        resetPickupStateData(){
            this.pickupStateList = [];
            this.pickupState = {};
            this.pickupStateError = false;
            this.pickupStateErrorMessage = '';
        },
        

        resetPickupCityData(){
            this.pickupCityList = [];
            this.pickupCity = {};
            this.pickupCityError = false;
            this.pickupCityErrorMessage = '';
        },
        
        
        onPickupCountryValueChange(event, withDefaultValue=false) {
            this.resetPickupStateData();
            this.resetPickupCityData();
            this.fetchPickupStateData(this.pickupCountry.id, withDefaultValue);
            this.onLocationChanged();
        },
        
        onPickupStateValueChange(event) {
            this.resetPickupCityData();
            this.fetchPickupCityData(this.pickupState.id);
            this.onLocationChanged();
        },
        
        onPickupCityValueChange(event) {
            this.onLocationChanged();
        },
        

        async fetchPickupCountryData(withDefaultValue=false){
            let response = await repository.regions.fetchCountries(null, 'pickup');
            if (response.success){
                this.pickupCountryList = response.data;
                if (withDefaultValue){
                    this.pickupCountry={id:160, name:'Nigeria'};
                    this.onPickupCountryValueChange(null, withDefaultValue)
                }
                return;
            }
            // setTimeout(this.fetchPickupCountryData(), 10000);
        },
        
        async fetchPickupStateData(countryID, withDefaultValue=false){
            if (!countryID) return;
            const data = {
                countryID: countryID
            }
            let response = await repository.regions.fetchStates(data, 'pickup');
            if (response.success){
                this.pickupStateList = response.data;
                if (withDefaultValue){
                    this.pickupState={id:2671, name:'Lagos'};
                    this.onPickupStateValueChange(null)
                }
                return;
            }
            // setTimeout(this.fetchPickupStateData(), 10000);
        },
        
        async fetchPickupCityData(stateID){
            if (!stateID) return;
            const data = {
                stateID: stateID,
            }
            let response = await repository.regions.fetchCities(data, 'pickup');
            if (response.success){
                this.pickupCityList = response.data;
                return;
            }
            // setTimeout(this.fetchPickupCityData(), 10000);
        },
        
        async onLocationChanged() {
            if (!this.locationDataComplete() || this.useSubscription) return;
            console.log("delivery detail list is ", this.deliveryDetails)

            for (let detail of this.deliveryDetails) {
                if (detail.price || detail.priceList) continue;
                const data = {
                    from:{
                        "countryID": this.pickupCountry.id,
                        "stateID": this.pickupState.id,
                        "cityID": this.pickupCity.id,
                    },
                    to:{
                        "countryID": detail.country_id,
                        "stateID": detail.state_id,
                        "cityID": detail.city_id,
                    }
                };

                console.log("calculating price for detail ", detail, " and data is ", data)
                
                const priceDataResponse = await repository.regions.queryPriceData(data);
                console.log("price data response for detail ", detail, " is ", priceDataResponse)
                if (priceDataResponse.success && priceDataResponse.data.price) {
                    detail.price = priceDataResponse.data.price;
                    detail.priceList = priceDataResponse.data.priceList;

                    console.log("at the end detail is ", detail)

                    // this.showPriceEstimate=true;
                    // this.estimatedPrice = priceDataResponse.data.price;
                    // this.estimatedPriceList = priceDataResponse.data.priceList;
                    return;
                }else{
                    detail.price = priceDataResponse.data
                    console.log("at the end detail is ", detail)
                }
                
                // this.estimatedPrice = priceDataResponse.data;
            }

            
        },
        locationDataComplete() {
            return this.pickupCountry.id>0 && this.pickupState.id>0 && this.pickupCity.id>0 && this.deliveryDetails.length>0
        },



















































        async placeOrder() {
            if (this.loading) return
            this.resetData()
            const orderValidation = this.validateOrderData()
            if (!orderValidation.success) return
            this.loading = true
            const data = {
                userID: this.$route.params.user_id,
                pickupName: this.pickupName,
                pickupAddress: this.pickupAddress,
                pickupCityID: this.pickupCity.id,
                pickupStateID: this.pickupState.id,
                pickupCountryID: this.pickupCountry.id,
                pickupPhone: this.pickupPhone,
                pickupEmail: this.pickupEmail,
                pickupExtraInformation: this.pickupExtraInformation,
                itemDescription: this.itemDescription,
                deliveryDetails: this.deliveryDetails,
                excelFile: this.excelFile,
                // deliveryName: this.deliveryName,
                // deliveryAddress: this.deliveryAddress,
                // deliveryCityID: this.deliveryCity.id,
                // deliveryStateID: this.deliveryState.id,
                // deliveryCountryID: this.deliveryCountry.id,
                // deliveryPhone: this.deliveryPhone,
                // deliveryEmail: this.deliveryEmail,
                // deliveryExtraInformation: this.deliveryExtraInformation,
                useSubscription: this.useSubscription,
                subscriptionAddressID: this.subscriptionSelectedAddressID,

                pickupCountryName: this.pickupCountry.name,
                pickupStateName: this.pickupState.name,
                pickupCityName: this.pickupCity.name,
                // deliveryCountryName: this.deliveryCountry.name,
                // deliveryStateName: this.deliveryState.name,
                // deliveryCityName: this.deliveryCity.name,
            }

            console.log("data from form is ", data);

            const response = await repository.order.createNewOrder(data);
            console.log("response after creating order is ", response)
            this.loading = false
            if (response.success) {
                // this.clearFormData();
                if (response.data.length == 1){

                    data.deliveryName =  response.data[0].delivery_name,
                    data.deliveryAddress = response.data[0].delivery_address,
                    data.deliveryCityID = response.data[0].delivery_city_id,
                    data.deliveryCityName = response.data[0].delivery_city.name,
                    data.deliveryStateID = response.data[0].delivery_state_id,
                    data.deliveryStateName = response.data[0].delivery_state.name,
                    data.deliveryCountryID = response.data[0].delivery_country_id,
                    data.deliveryCountryName = response.data[0].delivery_country.name,
                    data.deliveryPhone = response.data[0].delivery_phone,
                    data.deliveryEmail = response.data[0].delivery_email,
                    data.deliveryExtraInformation = response.data[0].delivery_extra_information,

                    data.orderID = response.data[0].orderID;
                    data.priceData = response.data[0].priceData

                    console.log("data going to confirm page is ", data);

                    this.$router.push({name: 'ConfirmOrder', params:{ user_id:this.$route.params.user_id,  orderData: JSON.stringify(data) }})
                    return
                }
                this.showSuccessMessage('Order Created');
                // }else{


                //     let orderIDList = [];
                //     for (var order of response.data){
                //         let newData = {
                //             "orderID":order.orderID,
                //             "status": "pending",
                //         }
                //         const response = await repository.order.confirmOrder(newData);
                //         console.log('response is after confirm order is', response)
                //         this.loading = false

                //     }
                //     this.showSuccessMessage('Order Created');
                    
                    
                    
                    
                    // if (response.success) {
                        
                    //     return
                    // }
                    // this.showErrorMessage(response.data)
                // }
                
                
            }else{
                this.showErrorMessage(response.data)
            }
            // this.showErrorMessage(response.data)
        },
        validateOrderData() {
            const returnValue = new Object()
            if (!this.pickupName) {
                this.pickupNameError = true
                this.pickupNameErrorMessage = 'Enter pickup name'
                returnValue.success = false
                return returnValue
            }
            if (!this.pickupPhone) {
                this.pickupPhoneError = true
                this.pickupPhoneErrorMessage = 'Enter pickup phone'
                returnValue.success = false
                return returnValue
            }
            if (this.useSubscription){
                if(!this.subscriptionSelectedAddressID){
                    this.subscriptionSelectedAddressIDError=true;
                    this.subscriptionSelectedAddressIDErrorMessage='Select Address To Use';
                    returnValue.success = false
                    return returnValue
                }
            }else{
                if (!this.pickupAddress) {
                    this.pickupAddressError = true
                    this.pickupAddressErrorMessage = 'Enter pickup address'
                    returnValue.success = false
                    return returnValue
                }
                if (!this.pickupCountry.id) {
                    this.pickupCountryError = true
                    this.pickupCountryErrorMessage = 'Select a country'
                    returnValue.success = false
                    return returnValue
                }
                if (!this.pickupState.id) {
                    this.pickupStateError = true
                    this.pickupStateErrorMessage = 'Select state'
                    returnValue.success = false
                    return returnValue
                }
                if (!this.pickupCity.id) {
                    this.pickupCityError = true
                    this.pickupCityErrorMessage = 'Select city'
                    returnValue.success = false
                    return returnValue
                }
            }
            
            if (!this.itemDescription) {
                this.itemDescriptionError = true
                this.itemDescriptionErrorMessage = 'Enter Item Description'
                returnValue.success = false
                return returnValue
            }
            
            returnValue.success = true
            return returnValue
        },
        resetData() {
            this.pickupNameError=false;
            this.pickupPhoneError=false;
            this.pickupEmailError=false;
            this.pickupAddressError=false;
            this.pickupCountryError=false;
            this.pickupStateError=false;
            this.pickupCityError=false;
            this.itemDescriptionError=false;
            this.error = false
            this.errorMessage = ''
        },
        showSuccessMessage (message) {
            this.loading=true;
            swal(message, "", "success")
            setTimeout(function(){
                window.location.href=`${process.env.BASE_URL}vieworders`
            }, 1000)
        },
        showErrorMessage (message) {
            this.error = true
            this.errorMessage = message
        },
        clearFormData(){
            // this.showPriceEstimate = false
            // this.estimatedPrice = ''
            // this.estimatedPriceList=[]
            this.pickupName = ''
            this.pickupPhone = ''
            this.pickupEmail = ''
            this.pickupAddress = ''
            this.pickupExtraInformation = ''
            this.itemDescription = ''
            
        },









        
        async loadCustomerData() {
            const user_id = this.$route.params.user_id;
            if (!user_id) window.location.href=`${process.env.BASE_URL}customers`
            let response = await repository.customer.loadCustomerData(user_id);
            if (response.success){
                this.customer = response.data;
                console.log(this.customer)
                return;
            }
            window.location.href=`${process.env.BASE_URL}customers`
        },

        onUseSubscriptionValueChange() {

        }
    }
}
</script>


<style scoped>
@import url('/assets/styles/vendor_components/sweetalert/sweetalert.css');
</style>

