<template>
    <div id="new-delivery" class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel2" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="myModalLabel2">Delivery</h4>
                    <button ref="add_delivery_close_button" type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                </div>
                <div class="modal-body">
                    <form class="form-horizontal form-element">
                        <div class="col-md-12 mb-20">
                            <p class="text-danger text-sm" v-if="error">{{errorMessage}}</p>
                        </div>
                        <div class="col-md-12 mb-20">
                          <div class="form-group">
                              <input v-model="deliveryName" type="text" class="form-control" placeholder="Name" required>
                              <p class="error text-sm" v-if="deliveryNameError">{{deliveryNameErrorMessage}}</p>
                          </div>
                          <div class="form-group">
                              <input v-model="deliveryPhone" type="text" class="form-control" placeholder="Phone" required>
                              <p class="error text-sm" v-if="deliveryPhoneError">{{deliveryPhoneErrorMessage}}</p>
                          </div>
                          <div class="form-group">
                              <input v-model="deliveryEmail" type="text" class="form-control" placeholder="Email" required>
                              <p class="error text-sm" v-if="deliveryEmailError">{{deliveryEmailErrorMessage}}</p>
                          </div>
                          <div class="form-group">
                              <input v-model="deliveryAddress" type="text" class="form-control" placeholder="Address" required>
                              <p class="error text-sm" v-if="deliveryAddressError">{{deliveryAddressErrorMessage}}</p>
                          </div>
                          <div class="form-group">
                              <label class="text-sm">Select Country</label>
                              <select v-model="deliveryCountry" @change="onDeliveryCountryValueChange" class="form-control">
                                  <option v-for="deliveryCountry in deliveryCountryList" :key="deliveryCountry.id" :value="deliveryCountry">{{deliveryCountry.name}}</option>
                              </select>
                              <p class="error text-sm" v-if="deliveryCountryError">{{deliveryCountryErrorMessage}}</p>
                          </div>
                          <div v-if="deliveryStateList.length" @change="onDeliveryStateValueChange" class="form-group">
                              <label class="text-sm">Select State</label>
                              <select v-model="deliveryState" class="form-control">
                                  <option v-for="deliveryState in deliveryStateList" :key="deliveryState.id" :value="deliveryState">{{deliveryState.name}}</option>
                              </select>
                              <p class="error text-sm" v-if="deliveryStateError">{{deliveryStateErrorMessage}}</p>
                          </div>
                          <div v-if="deliveryCityList.length" @change="onDeliveryCityValueChange" class="form-group">
                              <label class="text-sm">Select City</label>
                              <select v-model="deliveryCity" class="form-control">
                                  <option v-for="deliveryCity in deliveryCityList" :key="deliveryCity.id" :value="deliveryCity">{{deliveryCity.name}}</option>
                              </select>
                              <p class="error text-sm" v-if="deliveryCityError">{{deliveryCityErrorMessage}}</p>
                          </div>
                          <div class="form-group">
                              <div class="controls">
                                  <textarea cols="8" rows="6" v-model="deliveryExtraInformation" class="form-control" placeholder="Extra Information For Delivery (optional)"></textarea>
                              </div>
                              <p class="error text-sm" v-if="deliveryExtraInformationError">{{deliveryExtraInformationErrorMessage}}</p>
                          </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-info" @click="saveDeliveryDetail">Save<span v-if="loading" class="spinner-border text-white"></span></button>
                    <button type="button" class="btn btn-default float-right" data-dismiss="modal">Cancel</button>
                </div>
                <div class="col-md-12 mb-20">
                    <p class="text-danger text-sm" v-if="error">{{errorMessage}}</p>
                </div>
            </div>
            <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
    </div>
</template>

<script>
import { repository } from '../../presenter'
export default {
  name: 'DeliveryDetail',
  props: ['pickupCountryList'],
  mounted() {
    const sweetAlert = document.createElement('script')
    sweetAlert.setAttribute('src', `${this.publicPath}assets/styles/vendor_components/sweetalert/sweetalert.min.js`)
    document.head.appendChild(sweetAlert)

    this.fetchDeliveryCountryData(true);
  },
  data () {
    return {
      publicPath: process.env.BASE_URL,



      deliveryCountryList: [],
      deliveryCountry: {},
      deliveryCountryName: '',
      deliveryCountryError: false,
      deliveryCountryErrorMessage: '',

      deliveryStateList: [],
      deliveryState: {},
      deliveryStateName: '',
      deliveryStateError: false,
      deliveryStateErrorMessage: '',

      deliveryCityList: [],
      deliveryCity: {},
      deliveryCityName: '',
      deliveryCityError: false,
      deliveryCityErrorMessage: '',

      deliveryName: '',
      deliveryNameError: false,
      deliveryNameErrorMessage: '',
      deliveryPhone: '',
      deliveryPhoneError: false,
      deliveryPhoneErrorMessage: '',
      deliveryEmail: '',
      deliveryEmailError: false,
      deliveryEmailErrorMessage: '',
      deliveryAddress: '',
      deliveryAddressError: false,
      deliveryAddressErrorMessage: '',
      deliveryCountryError: false,
      deliveryCountryErrorMessage: '',
      deliveryStateError: false,
      deliveryStateErrorMessage: '',
      deliveryCityError: false,
      deliveryCityErrorMessage: '',
      deliveryExtraInformation: '',
      deliveryExtraInformationError: false,
      deliveryExtraInformationErrorMessage: '',














      email: '',
      emailError: '',
      emailErrorMessage: '',
      firstName: '',
      firstNameError: '',
      firstNameErrorMessage: '',
      lastName: '',
      lastNameError: '',
      lastNameErrorMessage: '',
      phone: '',
      phoneError: '',
      phoneErrorMessage: '',
      roleId: '',
      roleError: '',
      roleErrorMessage: '',
      generalError: '',
      generalErrorMessage: '',
      loading: false,
      success: false,
      successMessage: '',
      error: false,
      errorMessage: ''
    }
  },
  methods: {
    async fetchDeliveryCountryData(withDefaultValue=false){
    //   if (this.pickupCountryList!==null && this.pickupCountryList.length!=0){
    //       this.deliveryCountryList=this.pickupCountryList;
    //       return;
    //   }
      let response = await repository.regions.fetchCountries(null, 'delivery');
      if (response.success){
          this.deliveryCountryList = response.data;
          if (withDefaultValue){
              this.deliveryCountry={id:160, name:'Nigeria'};
              this.onDeliveryCountryValueChange(null)
          }
          return;
      }
    },


    resetDeliveryCountryData(){
        this.deliveryCountryList = [];
        this.deliveryCountry = {};
        this.deliveryCountryError = false;
        this.deliveryCountryErrorMessage = '';
    },

    resetDeliveryStateData(){
        this.deliveryStateList = [];
        this.deliveryState = {};
        this.deliveryStateError = false;
        this.deliveryStateErrorMessage = '';
    },

    resetDeliveryCityData(){
        this.deliveryCityList = [];
        this.deliveryCity = {};
        this.deliveryCityError = false;
        this.deliveryCityErrorMessage = '';
    },

    onDeliveryCountryValueChange(event, withDefaultValue=false) {
        this.resetDeliveryStateData();
        this.resetDeliveryCityData();
        this.fetchDeliveryStateData(this.deliveryCountry.id, withDefaultValue);
        // this.onLocationChanged();
    },

    onDeliveryStateValueChange(event) {
        this.resetDeliveryCityData();
        this.fetchDeliveryCityData(this.deliveryState.id);
        // this.onLocationChanged();
    },

    onDeliveryCityValueChange(event) {
        // this.onLocationChanged();
    },

    async fetchDeliveryStateData(countryID, withDefaultValue=false){
        if (!countryID) return;
        const data = {
            countryID: countryID,
        }
        let response = await repository.regions.fetchStates(data, 'delivery');
        if (response.success){
            this.deliveryStateList = response.data;
            if (withDefaultValue){
                this.deliveryState={id:2671, name:'Lagos'};
                this.onDeliveryStateValueChange(null)
            }
            return;
        }
    },


    async fetchDeliveryCityData(stateID){
        if (!stateID) return;
        const data = {
            stateID: stateID,
        }
        let response = await repository.regions.fetchCities(data, 'delivery');
        if (response.success){
            this.deliveryCityList = response.data;
            return;
        }
        // setTimeout(this.fetchPickupCityData(), 10000);
    },



















    async saveDeliveryDetail() {
      if (this.loading) return
      this.resetData()
      const deliveryDataValidation = this.validateDeliveryData()
      if (!deliveryDataValidation.success) return;
      this.loading = true
      const data = {
        name: this.deliveryName,
        email: this.deliveryEmail,
        phone: this.deliveryPhone,
        address: this.deliveryAddress,
        country_id: this.deliveryCountry.id,
        countryName: this.deliveryCountry.name,
        state_id: this.deliveryState.id,
        stateName: this.deliveryState.name,
        city_id: this.deliveryCity.id,
        cityName: this.deliveryCity.name,
        extra_information: this.deliveryExtraInformation
      }
      console.log('data is ', data)
      this.$emit("saveDetail", data);
      this.clearFormData();
      this.$refs.add_delivery_close_button.click();
      this.loading = false
    },

    validateDeliveryData () {
        const returnValue = new Object()
        if (!this.deliveryName) {
            this.deliveryNameError = true
            this.deliveryNameErrorMessage = 'Enter delivery name'
            returnValue.success = false
            return returnValue
        }
        if (!this.deliveryPhone) {
            this.deliveryPhoneError = true
            this.deliveryPhoneErrorMessage = 'Enter delivery phone'
            returnValue.success = false
            return returnValue
        }
        if (!this.deliveryAddress) {
            this.deliveryAddressError = true
            this.deliveryAddressErrorMessage = 'Enter delivery address'
            returnValue.success = false
            return returnValue
        }
        if (!this.deliveryCountry.id) {
            this.deliveryCountryError = true
            this.deliveryCountryErrorMessage = 'Select a country'
            returnValue.success = false
            return returnValue
        }
        if (!this.deliveryState.id) {
            this.deliveryStateError = true
            this.deliveryStateErrorMessage = 'Select state'
            returnValue.success = false
            return returnValue
        }
        if (!this.deliveryCity.id) {
            this.deliveryCityError = true
            this.deliveryCityErrorMessage = 'Select city'
            returnValue.success = false
            return returnValue
        }
        returnValue.success = true
        return returnValue
    },

    

    resetData () {
        this.deliveryNameError=false;
        this.deliveryPhoneError=false;
        this.deliveryEmailError=false;
        this.deliveryAddressError=false;
        this.deliveryCountryError=false;
        this.deliveryStateError=false;
        this.deliveryCityError=false;
        this.error = false
        this.errorMessage = ''
    },

    clearFormData() {
        this.deliveryName = ''
        this.deliveryPhone = ''
        this.deliveryEmail = ''
        this.deliveryAddress = ''
        this.deliveryExtraInformation = ''
    }
  }
}
</script>

<style scoped>
@import url('/assets/styles/vendor_components/sweetalert/sweetalert.css');
</style>

